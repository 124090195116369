.board {
  position: static; /* or absolute */
  top: 50%;
  left: 50%;
}

.cellrow {
  display: inherit;
  height: 25px;
  margin: 0;
  padding: 0;
}

.mini-margin {
  margin: 10px;
}
